const fetchProductData = async (handle) => {
  try {
    const response = await fetch(`/products/${handle}?view=data`)
    if (!response.ok) {
      throw new Error(`Failed to fetch product: ${handle}`)
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching product data:', error)
    return null
  }
}

export { fetchProductData }
